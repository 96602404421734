import React, {useState, useEffect} from 'react';
import axios from 'axios';
import config from "../config";

const AuthContext = React.createContext({});
const AuthConsumer = AuthContext.Consumer;

const AuthProvider = ({children}) => {
    const [ token, setToken ] = useState(sessionStorage.getItem('token'))
    const [ loading, setLoading ] = useState(false);
    const [ message, setMessage ] = useState('');

    axios.defaults.headers.common = {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    };

    useEffect(() => {
        if (token) {
            sessionStorage.setItem('token', token);
        } else {
            sessionStorage.removeItem('token');
        }
    }, [token])

    if (token) {
        axios.defaults.headers.common = {
            Authorization: token
        };
    } else {
        axios.defaults.headers.common = {};
    }

    const login = (password) => {
        setLoading(true)
        setMessage('')
        axios.get(config.url + '/items',{
            headers: { Authorization: password }
        })
        .then(({data}) => {
            setToken(password)
        })
        .catch((error) => {
            setMessage('Erreur')
        })
        .finally(() => setLoading(false))
    }

    const logout = () => {
        setToken(null)
    }

    const isAuthenticated = () => {
        return !!token;
    }

    const isGuest = () => {
        return !isAuthenticated();
    }

    return (
        <AuthContext.Provider value={{
            login: login,
            logout: logout,

            isAuthenticated: isAuthenticated,
            isGuest: isGuest,

            loading: loading,
            message: message,
            // errors: errors,

        }}>
            {children}
        </AuthContext.Provider>
    );
}

export { AuthContext, AuthProvider, AuthConsumer };