const theme = {
    global: {
        font: {
            family: "'Roboto', 'Helvetica Neue'",
        },
        colors: {
            brand: '#85c556'
        },
        input: {
            weight: 300,
        },
        active: {
            background: {
                opacity: "none"
            }
        }
    },
    layer: {
        overlay: {
            background: 'rgba(0, 0, 0, 0.8)'
        }
    },
    anchor: {
        fontWeight: 300,
        color: {
            "dark": "accent-1",
            "light": "dark-1"
        },
        hover: {
            // textDecoration: 'none'
        }
    },
    button: {
        border: {
            radius: "6px",
        },
        color: "#fff"
    },

    formField: {
        label: {
            color: "dark-2",
            size: "small",
            margin: {vertical: "0", bottom:"xsmall", horizontal: "0"},
            weight: 300
        },
        border: {
            color: "brand",
            side: "all",
            round: "4px"
        }
    }
};

export default theme;
