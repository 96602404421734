import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter as Router} from "react-router-dom";
import {Grommet} from "grommet";
import { AuthProvider } from "./auth/AuthContext";
import Authenticated from "./auth/Authenticated";
import Guest from "./auth/Guest";
import Login from "./auth/Login";
import Main from "./Main";

import theme from "./theme";
import {Navigation} from "./navigation";

ReactDOM.render(
  <React.StrictMode>
      <Grommet full theme={theme}>
          <AuthProvider>
          <Router>
              <Authenticated>
                  <Navigation>
                    <Main />
                  </Navigation>
              </Authenticated>
              <Guest>
                <Login />
              </Guest>
          </Router>
          </AuthProvider>
      </Grommet>
  </React.StrictMode>,
  document.getElementById('root')
);
