import React, { useEffect, useState, useContext } from "react";
import axios from "axios";
import config from "./config";
import {Box, Text, Anchor, Button, TextInput, InfiniteScroll, ResponsiveContext} from "grommet";
import { LinkPrevious, LinkNext, Home, Document, Search, Download, Close, FormSearch } from "grommet-icons";
import { ItemFile, ItemFolder } from "./Item";
// import PDF from "./PDF";
// import Viewer from "./Viewer";

const Loading = () =>
    <Box flex="grow" justify="center" align="center">Chargement...</Box>;


const Main = () => {
    const [ items, setItems ] = useState([])
    const [ path, setPath ] = useState([])
    const [ show, setShow ] = useState(null)
    //const [ search, setSearch ] = useState('')
    const [ loading, setLoading ] = useState(false)
    const size = useContext(ResponsiveContext);

    let delay = null;

    useEffect(() => {
        setLoading(true)
        getItems()
    }, [path])

    // useEffect(() => {
    //     getItems()
    // }, [loading])

    // useEffect(() => {
    //     if (search.length > 2 || search.length === 0) {
    //         clearTimeout(delay);

    //         delay = setTimeout(() => {
    //             setLoading(true)
    //             getItems()
    //             //this.props.setQueryParams({search: string});
    //             //setSession(this.hash, 'search', string)

    //         }, 400);
    //     }
    // }, [search])

    const getItems = () => {
        // setItems([])

        let url = config.url + '/items' + (path.length > 0 ? '/' + path[path.length - 1].id : '');

        // if (search.length > 2) {
        //     url = config.url + '/items?search=' + search;
        // }

        axios.get(url)
            .then(({data}) => {
                setItems(data)
            })
            .finally(() => setLoading(false))
            .catch(error => {
                console.log(error.response)
            });

    }

    const changeDir = (item) => {
        setPath([ ...path, item])
    }

    const goToParent = () => {
        setPath(path.slice(0, -1))
    }

    const goTo = (item) => {
        const idx = path.findIndex(p => p.id === item.id)
        if (idx !== -1) {
            setPath(path.slice(0, idx))
        }
    }

    const goToRoot = () => {
        setPath([])
    }

    //const clearSearch = () => setSearch('')

    return (
        <Box>
            <Box flex={false} pad="small">
                {/* <Box className="search" direction="row" round="xsmall" margin="small" border flex={false}>
                    {search.length > 0 ? <Button icon={<Close />} onClick={clearSearch} /> : <Button icon={<FormSearch />} />}
                    <TextInput plain type="text" value={search}
                               onChange={event => setSearch(event.target.value)} placeholder=""
                    />
                </Box> */}
                <Box pad="medium" gap="medium" border={{side: 'bottom'}} direction={size === 'small' ? "column" : "row"}>
                    <Box direction="row" gap="medium">
                        <Box gap="xsmall" direction="row">
                            <Text size="small"><Anchor icon={<Home size="small" />} onClick={goToRoot} label="Accueil" /></Text>
                        </Box>
                        {path.length > 0 && <Box gap="xsmall" direction="row">
                            <Text size="small"><Anchor icon={<LinkPrevious size="small" />} onClick={goToParent} label="Precedent" /></Text>
                        </Box>}
                    </Box>
                    {path.length > 0 && <Box gap="xsmall" direction={size === 'small' ? "column" : "row"}>
                        {
                            path.map((p, i) => {
                                if (path.length === i) {
                                    return <Text size="small" key={path.id}>/ <Anchor key={p.id} onClick={() => goTo(p)} label={p.name}/></Text>
                                } else {
                                    return <Text size="small" key={path.id}>/ {p.name}</Text>
                                }
                            })
                        }
                    </Box>}
                </Box>
            </Box>


            <Box pad="small" overflow="auto">
                {loading ? <Loading/> :
                    <InfiniteScroll items={items}>
                        {item => item.isFolder ?
                            <ItemFolder key={item.id} item={item} changeDir={changeDir}/> :
                            <ItemFile key={item.id} item={item} onClick={() => setShow(item)}/>}
                    </InfiniteScroll>
                }
            </Box>
            {/*{show && <PDF pdf={show} handleClose={() => setShow(null)} />}*/}
            {/*{show && <Viewer item={show} handleClose={() => setShow(null)} />}*/}
        </Box>

    )
}

export default Main;