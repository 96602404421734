import React from "react";
import {Document, Folder, LinkNext, View, Download} from "grommet-icons";
import {Box, Image, Text, Button} from "grommet";

const ItemThumbnail = ({thumbnails}) => {
    if (!thumbnails[2] || !thumbnails[2].small) {
        return <Document />
    }
    return <Image src={thumbnails[2].small.url} />
}

const ItemFolder = ({item, changeDir}) => {

    return (
        <Box onClick={() => changeDir(item)} hoverIndicator={true} pad="medium" gap="xsmall" border={{side: 'bottom'}} direction="row">
            <Folder/>
            <Text>{item.name}</Text>
            <Box flex="grow" alignSelf="center" align="end">
                <LinkNext />
            </Box>
        </Box>
    )
}

const ItemFile = ({ item, onClick }) => {

    return (
        <Box hoverIndicator={true} flex={false} pad="medium" gap="xsmall" border={{side: 'bottom'}} direction="row">
            {item.thumbnails.length > 0 ? <ItemThumbnail thumbnails={item.thumbnails}/> : <Document/>}
            <Text>{item.name}</Text>
            <Box flex="grow" alignSelf="start" justify="end" direction="row">
                <Button href={item.downloadUrl} target="_blank" icon={<Download/>}/>
                {/*<Button onClick={onClick} icon={<View/>}/>*/}
            </Box>
        </Box>
    );
}

export {
    ItemFile, ItemFolder
}