import React, { useContext } from 'react';
import { AuthContext } from "./AuthContext";

const Authenticated = ({children}) => {
    const { isAuthenticated } = useContext(AuthContext);

    return isAuthenticated() ? children : null;
};

export default Authenticated;
