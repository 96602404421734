import React, { useContext } from "react";
import { useRouteMatch, useHistory } from "react-router-dom";
import { Grid, Box, Text, Button, Image, ResponsiveContext } from "grommet";
import { Logout } from "grommet-icons";

import { AuthContext } from "../auth/AuthContext";

const NavigationPanel = ({children}) => {
    const count = React.Children.count(children);

    if (count === 2) {
        children = React.Children.toArray(children);
        return (
            <Grid fill rows={['auto']} columns={['flex', 'medium']}
                  areas={[{name: 'main', start: [0, 0], end: [0, 0]}, {name: 'side', start: [1, 0], end: [1, 0]}]}>
                <Box gridArea="main">{children[0]}</Box>
                <Box gridArea="side" background="light-1">{children[1]}</Box>
            </Grid>
        );
    }

    return <Box fill>{children}</Box>;
};

const NavigationButton = ({label, path, icon}) => {
    const history = useHistory();
    const match = useRouteMatch(path);

    const background = match ? "white" : null;

    return (
            <Button plain alignSelf="stretch"
                    onClick={() => history.push(path)} active={!!match} hoverIndicator="white">
                <Box pad={{vertical: "xsmall", horizontal: "medium"}}
                     gap="xxsmall" direction="row" background={background}>{icon} <Text>{label}</Text></Box>
            </Button>
    );
}

const NavigationLogo = () => {
    return (
        <Box pad="small" align="start">
            <Image fill src="logo.png" alt="FEDE" style={{width:"100px", margin: "0 auto"}} />
        </Box>
    );
};

const NavigationMobile = ({children}) => {
    const { logout } = useContext(AuthContext);
    return (
        <Box>
            <Box pad="xsmall" gap="small" justify="between" align="center" background="#EBE4DE" direction="row">
                <Box width="xsmall"  align="start">
                    <Image fill src="logo.png" alt="FEDE" />
                </Box>
                <Box>
                    <Button plain onClick={logout} icon={<Logout />} />
                </Box>
                {/*{menu && <NavigationLayer setClose={() => setMenu(false)} />}*/}
            </Box>
            <Box>
                {children}
            </Box>
            <Box>

            </Box>
        </Box>
    )
}

const Navigation = ({children}) => {
    const { logout } = useContext(AuthContext);
    const size = useContext(ResponsiveContext);

    if (size === 'small') {
        return <NavigationMobile>{children}</NavigationMobile>
    }

    return (
    <Grid rows={["100vh"]} columns={['small', 'flex']}
          areas={[{name: 'nav', start: [0, 0], end: [0, 0]}, {name: 'main', start: [1, 0], end: [1, 0]},]}>
        <Box gridArea="nav" background="#EBE4DE" fill>
            <NavigationLogo />

            <Box flex="grow"/>
            <Box pad={{vertical: 'medium'}}>
                <Button plain alignSelf="stretch" onClick={logout} hoverIndicator="white">
                    <Box pad={{vertical: "xsmall", horizontal: "medium"}}
                         gap="xxsmall" direction="row">{<Logout />} <Text style={{fontSize: '14px'}}>Se déconnecter</Text></Box>
                </Button>
            </Box>
            {/*{menu && <NavigationLayer setClose={() => setMenu(false)} />}*/}
        </Box>
        <Box gridArea="main">
            <NavigationPanel>
                {children}
            </NavigationPanel>
        </Box>
    </Grid>
    );
};

export default Navigation;
