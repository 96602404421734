import React, { useContext, useState } from "react";

import {Box, Text, Button, Stack, Form, TextInput, Image, ResponsiveContext} from "grommet";
import { FormLock, View } from "grommet-icons";

import { AuthContext } from "./AuthContext";

const PasswordInput = ({ value, onChange, placeholder, ...rest }) => {
    const [reveal, setReveal] = useState(false);

    return (
        <Stack anchor="right">
            <TextInput type={reveal ? "text" : "password"} value={value}
                       onChange={event => onChange(event.target.value)}
                       placeholder=""
                       {...rest}
            />
            <Button icon={reveal ? <FormLock size="medium" /> : <View size="medium" />}
                    onClick={() => setReveal(!reveal)}
            />
        </Stack>
    );
};

const Login = () => {
    const [ password, setPassword ] = useState('');
    const { message, errors, loading, login } = useContext(AuthContext)
    const size = useContext(ResponsiveContext);

    return (
        <Box fill gap="medium" align="center" justify="start">
            <Form onSubmit={() => login(password)}>
                <Box gap="small" width={size === 'small' ? "small" : "medium"}>
                    <Image fill src="logo.png" alt="FEDE" style={{width: '100px', margin: 'auto'}} />
                    <Text style={{textAlign: 'center'}}>Ressources Pédagogiques de la FEDE</Text>
                    <PasswordInput value={password} disabled={loading}
                                   onChange={(value) => setPassword(value)}
                    />
                    <Button type="submit" primary label="Login" disabled={loading} />
                    {message && <Text color="status-critical" style={{textAlign: 'center'}}>{message}</Text>}
                    {errors && Object.entries(errors).map(v => v[1].join(' '))}
                </Box>
            </Form>
        </Box>
    );

}

export default Login;
