import React, { useContext } from 'react';
import { AuthContext } from "./AuthContext";

const Guest = ({children}) => {
    const { isGuest } = useContext(AuthContext);

    return isGuest() ? children : null;
};

export default Guest;
